import Marquee from 'react-fast-marquee'
import { useApiNews } from '../hooks/useApi'

export const NewsTicker = () => {
  const { data, error, isLoading } = useApiNews()
  const newsList = data?.data
  if (error || (!isLoading && data?.code !== 200))
    console.error(`[NewsTicker] ${error} ${data}`)

  return (
    <Marquee direction='left' speed={28}>
      {newsList?.map((news, i) => (
        <label key={i} className='text-2xl pr-[36rem] '>
          {news.content}
        </label>
      ))}
    </Marquee>
  )
}
